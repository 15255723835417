import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import Layout from '../../../components/Layout'
import styled from 'styled-components';

import "./style.css"


const ImageWraper = styled.div`

  text-align: center;  
  margin-bottom: 20px;

  height: auto;
  width: auto;  

  @media screen and (max-width: 960px) {
    width: auto;  
  }

`



const Information = () => {

  return (
      <div className="boda-box">
          <br/>
          <h1>Deutschland</h1>
            <ImageWraper>
              <StaticImage className="container" src="../../../images/wedding/frankfurt_cuadrada.jpg"  width={1200}  height={1200} />                                  
            </ImageWraper>   
            <div>
              <h1>SCHEDULE</h1>
              <br/>
              <h2>CIVIL WEDDING</h2>
              <br/>
              <p>15:00h</p>              
              <h3>Arrival at Prinzengarten of Burg Kronberg</h3>
              <br/>              
              <p>15:30h</p>              
              <h3>Ceremony</h3>
              <br/>              
              <p>16:00h</p>                
              <h3>Toast</h3>
              <br/>              
              <p>18:00h</p>
              <h3>Party at Weingut</h3>
              <br/>                   
              <br/> 
            </div>
            <h2>Addresses</h2>
            <br/>
              <p>Prinzengarten Burg Kronberg</p>              
              <p><a href="https://goo.gl/maps/kpdUwVTodVR1aw8X6" target="_blank" rel="noreferrer" >Burg Kronberg, Schloßstraße 10, 61476 Kronberg im Taunus</a></p>
              <br/>              
              <p>Weingut Carl Koch</p>                            
              <p><a href="https://goo.gl/maps/VmuCPkafnWG49Xbe6" target="_blank" rel="noreferrer" >
                Weingut Bürgermeister Carl Koch
                <br/>
                Wormser Straße 62, 55276 Oppenheim</a>
                </p>              
              <br/>     
              <br/>         
              <h2>HOW TO GET TO KRONBERG</h2>
              <br/>
              <p>If you come with public transport, take the S4 to the final station Kronberg im Taunus. A bus can take you up the hill to Berliner Platz. Then you will have to walk to the castle (20 minutes). </p>
              <br/>
              <p>If you come by car, please use the parking garage at Berliner Platz. You will have to walk up to the castle.</p>
              <br/>
              <br/>
              <h2>HOW TO GET TO OPPENHEIM</h2>
              <br/>
              <p>Some people come by car and will take the guests without car.</p>
              <br/>            
              <p>There is a parking spot at 7 minutes walking distance from the location : <a rel="noreferrer"  href="https://goo.gl/maps/Q8tTgjBoBDRNCwy88" target="_blank">Zentralparkplatz Kellerlabyrinth</a>.</p>
              <br/>
              <p>If you need to check in at your hotel in Oppenheim, you can do so after arriving in Oppenheim.</p>
              <br/>
              <p>Please try to be at the location lastest 18:40h.</p>
              <br/>
              <h2>WHERE TO STAY?</h2>
              <br/>
              <p>The wedding is on the 22nd of July but you could also stay in Frankfurt and come to the ceremony by train (S4). It takes 20 minutes from Frankfurt Hbf.</p>
              <br/>                 
              <p>We will sleep in Oppenheim on the 22nd and 23rd of July and we recommend you to sleep in Oppenheim at least the 22nd of July after the party.</p>
              <br/>                 
              <p>We can recomend the following hotel:</p>
              <p><a href="https://www.altesamtsgerichtoppenheim.de/" rel="noreferrer" target="_blank">https://www.altesamtsgerichtoppenheim.de/</a></p>
              <br/>
              <br/>
              <h2>SATURDAY 23</h2>
              <br/>
              <p>On Saturday we will spend the day in Oppenheim and invite you to join us.</p>
              <br/>
              <p>Please note the activities are not part of the official wedding celebration. They are completely voluntary and at your own cost.</p>
              <br/>
              <p>At 11am we will meet for brunch at <a href="https://g.page/genussheldin?share" rel="noreferrer"  target="_blank">Café Genussheldin</a>.</p>
              <br/>
              <p>There will be bread with ham, cheese and jam for everyone (8,90€ per person) and you will be able to order any extra and drink you want aside.</p>
              <br/><br/>
              <p>If the wheather is nice, you can join us for a swim at <a href="https://goo.gl/maps/aaT3UTxwQ6yNirsg8" target="_blank" rel="noreferrer" >Strandbad Oppenheim</a>.
              <br/>
               Make sure to bring your swimwear and towell.</p>
              <br/><br/>
              <p>At 18pm we made a reservation to have dinner together at <a rel="noreferrer" href="https://goo.gl/maps/oEZY7qxP4UfYqWAB7" target="_blank">Rheinrestaurant Oppenheim</a>.</p>
              <br/><br/>              
              <br/>            
        </div>
    )
}



const Page = () => {

  return (
    <Layout section="wedding" pageTitle="Wedding">
      <Information />
    </Layout>
  )
}

export default Page